// Country Chooser

@import './../../../../scss/theme-bootstrap';

.country-chooser {
  margin-bottom: 22px;
  @include breakpoint($medium-up) {
    margin-bottom: 0;
    display: inline-block;
  }
}

.country-select-img {
  vertical-align: top;
  @include swap_direction(margin, 9px auto 0 auto);
  @include breakpoint($medium-up) {
    display: inline-block;
    @include swap_direction(margin, 14px 0 0 15px);
    &.mobile-hidden {
      display: inline-block;
    }
    &.pc-hidden {
      display: none;
    }
  }
}

.country-select__menu {
  display: inline-block;
  .touchevents & {
    background: url(/media/images/global/country_select_arrow.gif) no-repeat 100% 5px;
  }
}

.country-select__selectbox {
  &.selectBox {
    color: $color-white;
    font-family: $font--avenir-light;
    font-size: 19px;
    line-height: 34px;
    background: $color-black;
    @include breakpoint($medium-up) {
      line-height: 45px;
      font-family: $font--avenir-medium;
      font-size: 12px;
      letter-spacing: 0.025em;
      text-transform: uppercase;
    }
    .selectBox-label {
      background: $color-black;
      border: 0;
      @include swap_direction(padding, 0 45px 0 10px);
      width: auto;
      &:after {
        background: $color-black;
        color: $color-white;
      }
    }
  }
}

.country-select__selectbox-selectBox-dropdown-menu {
  padding: 0;
  border-top: 1px solid $color-dark-gray;
  border-left: 1px solid $color-dark-gray;
  border-right: 1px solid $color-dark-gray;
  &.selectBox-dropdown-menu {
    a {
      @include swap_direction(padding, 0 45px 0 10px);
      display: block;
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid $color-dark-gray;
    }
  }
}
